// indexedDBHelper.js

export const openOfflineFormsDB = async () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("offlineForms", 1);

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore("forms", { autoIncrement: true });
        };

        request.onsuccess = function(event) {
            resolve(event.target.result);
        };

        request.onerror = function(event) {
            reject("Error opening IndexedDB: " + event.target.errorCode);
        };
    });
};

export const addForm = async (db, form) => {
    return new Promise((resolve, reject) => {
        const tx = db.transaction("forms", "readwrite");
        const store = tx.objectStore("forms");
        const request = store.add(form);

        request.onsuccess = function() {
            resolve(request.result);
        };

        request.onerror = function() {
            reject("Error adding form to IndexedDB: " + request.error);
        };
    });
};

export const getOfflineForms = async (db) => {
    return new Promise((resolve, reject) => {
        const tx = db.transaction("forms", "readonly");
        const store = tx.objectStore("forms");
        const request = store.getAll();

        request.onsuccess = function() {
            resolve(request.result);
        };

        request.onerror = function() {
            reject("Error getting offline forms from IndexedDB: " + request.error);
        };
    });
};
