import React, { useState, useEffect, useRef } from 'react';

function Tests() {
    const videoRef = useRef(null);
    const [mediaStream, setMediaStream] = useState(null);
    const canvasRef = useRef(null);
    const [facingMode, setFacingMode] = useState("user");
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [capturedImageSrc, setCapturedImageSrc] = useState(null);

    const handleGetCamera = async () => {
        // Stop the camera if it's already running
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
        }
        try {
            const constraints = {
                video: {
                    facingMode
                }
            };
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
            setMediaStream(stream);
        } catch (err) {
            console.error("Error accessing the camera:", err);
        }
    };

    const handleStopCamera = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
            setMediaStream(null);
        }
    };

    const handleCapture = () => {
        const ctx = canvasRef.current.getContext('2d');
        ctx.drawImage(videoRef.current, 0, 0, 640, 480);
        const capturedImage = canvasRef.current.toDataURL('image/png');
        setCapturedImageSrc(capturedImage);

        handleStopCamera();  // stop the camera after capturing the image
    };

    const toggleCamera = () => {
        setFacingMode(prevMode => prevMode === "user" ? "environment" : "user");
        handleGetCamera();
    };

    const handleGetLocation = () => {
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };

        navigator.geolocation.getCurrentPosition((position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
        }, (error) => {
            console.error("Error fetching geolocation:", error);
        }, options);
    };

    return (
        <div>
            <h2>Tests</h2>
            <button onClick={handleGetCamera}>Start Camera</button>
            <button onClick={toggleCamera}>Toggle Camera</button>
            <button onClick={handleStopCamera}>Stop Camera</button> {/* Newly added button to stop camera */}
            <button onClick={handleCapture}>Capture</button>
            <button onClick={handleGetLocation}>Get Location</button>
            <input type="text" placeholder="Latitude" value={latitude || ''} readOnly />
            <input type="text" placeholder="Longitude" value={longitude || ''} readOnly />
            <video ref={videoRef} autoPlay width="640" height="480"></video>
            <canvas ref={canvasRef} width="640" height="480" style={{ display: 'none' }}></canvas>
            {capturedImageSrc && <img src={capturedImageSrc} alt="Captured" />} {/* Display the captured image */}
        </div>
    );
}

export default Tests;
