import React, { useState, useEffect } from 'react'; // <-- Add this line for React hooks
import { openOfflineFormsDB } from './indexedDBHelper'; // <-- Add this line for the IndexedDB helper

function OfflineForms() {
    const [forms, setForms] = useState([]);

    useEffect(() => {
        const fetchForms = async () => {
            const db = await openOfflineFormsDB();
            const transaction = db.transaction(['forms'], 'readonly');
            const store = transaction.objectStore('forms');
            const getAllRequest = store.getAll();
            getAllRequest.onsuccess = function () {
                setForms(getAllRequest.result);
            };
        };

        fetchForms();
    }, []);

    return (
        <div>
            <h2>Offline Forms</h2>
            <ul>
                {forms.map((form, index) => (
                    <li key={index}>
                        {`Form ${index + 1} (${form.name}): `}
                        {`${form.Organisation.name} (${form.Organisation.id}) - `}
                        {`${form.Programme.name} (${form.Programme.id}) - `}
                        {`${form.Project.name} (${form.Project.id}) - `}
                        {`${form.Site.name} (${form.Site.id}) - `}
                        {`${form.MooringType.name} (${form.MooringType.id}) - `}
                        {`${form.Station.name} (${form.Station.id}) - `}
                        {`${form.Mooring.name} (${form.Mooring.id}) - `}
                        {`${form.Deployment.name} (${form.Deployment.id}) - `}
                        {`${form.Logger?.name} (${form.Logger?.id})`}
                    </li>
                ))}
            </ul>
        </div>
    );

}

export default OfflineForms;
