
export const openDB = () => {
    return new Promise((resolve, reject) => {
        const dbRequest = indexedDB.open("MyDatabase", 3);

        dbRequest.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('userData')) {
                db.createObjectStore('userData');
            }
            if (!db.objectStoreNames.contains('pendingRegistrations')) {
                db.createObjectStore('pendingRegistrations');
            }
        };

        dbRequest.onsuccess = (event) => {
            resolve(event.target.result);
        };

        dbRequest.onerror = (event) => {
            reject("Error opening or setting up DB:", event.target.error);
        };
    });
};


export const retrieveUserData = async () => {
    const db = await openDB();
    const transaction = db.transaction(['userData'], 'readonly');
    const store = transaction.objectStore('userData');

    // Using promise-based approach to handle IDBRequest
    const tokenRequest = store.get('authToken');
    const usernameRequest = store.get('username');

    const token = await new Promise((resolve, reject) => {
        tokenRequest.onsuccess = () => resolve(tokenRequest.result);
        tokenRequest.onerror = (event) => reject(event.target.error);
    });

    const username = await new Promise((resolve, reject) => {
        usernameRequest.onsuccess = () => resolve(usernameRequest.result);
        usernameRequest.onerror = (event) => reject(event.target.error);
    });

    return { token, username };
};


export const deleteUserDataFromDB = async () => {
    const db = await openDB();
    const transaction = db.transaction(['userData'], 'readwrite');
    const store = transaction.objectStore('userData');

    await store.delete('authToken');
    await store.delete('username');
};

export const sendPendingRegistrations = async () => {
    // Check for online status
    if (navigator.onLine) {
        const db = await openDB();

        // Begin a transaction and get the object store
        const transaction = db.transaction('pendingRegistrations', 'readonly');
        const store = transaction.objectStore('pendingRegistrations');

        // Use the get method on the object store
        const offlineDataRequest = store.get('registrationData');
        const offlineDataArray = await new Promise((resolve, reject) => {
            offlineDataRequest.onsuccess = () => resolve(offlineDataRequest.result);
            offlineDataRequest.onerror = (event) => reject(event.target.error);
        });

        if (offlineDataArray && offlineDataArray.length) {
            for (let data of offlineDataArray) {
                try {
                    const response = await fetch('https://www.smcrioims.co.za/register', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    });

                    if (response.status === 201) {
                        console.log("Successfully sent pending registration data to the server.");

                        // If successful, remove this piece of data from the array
                        offlineDataArray.splice(offlineDataArray.indexOf(data), 1);

                        const updateTransaction = db.transaction('pendingRegistrations', 'readwrite');
                        const updateStore = updateTransaction.objectStore('pendingRegistrations');

                        // Update the offline data
                        const updateRequest = updateStore.put(offlineDataArray, 'registrationData');

                        await new Promise((resolve, reject) => {
                            updateRequest.onsuccess = () => resolve();
                            updateRequest.onerror = (event) => reject(event.target.error);
                        });

                    } else {
                        const errorData = await response.json();
                        throw new Error(errorData.error || 'Network response was not ok');
                    }

                } catch (error) {
                    console.error("Error sending registration data:", error);
                }
            }

            // Alert the user that the offline registration data was detected and is now synced
            if (offlineDataArray.length === 0) {
                window.alert("Offline registration data was detected and is now synced with the server.");

                // Notify using push notification
                if (navigator.serviceWorker.controller) {
                    navigator.serviceWorker.controller.postMessage({ type: 'SYNC_SUCCESS' });
                }
            }
        }
    }
};

export const retrieveUserDataFromIndexedDB = () => {
    return new Promise((resolve, reject) => {
        const dbRequest = indexedDB.open("MyDatabase", 3);

        dbRequest.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction(['userData'], 'readonly');
            const store = transaction.objectStore('userData');

            const tokenRequest = store.get('authToken');
            const usernameRequest = store.get('username');
            const roleIdRequest = store.get('role_id');  // Retrieving role ID

            let token, username, roleId;

            tokenRequest.onsuccess = () => {
                token = tokenRequest.result;

                usernameRequest.onsuccess = () => {
                    username = usernameRequest.result;

                    roleIdRequest.onsuccess = () => {
                        roleId = roleIdRequest.result;
                        resolve({ token, username, roleId });
                    };

                    roleIdRequest.onerror = (e) => {
                        reject("Error retrieving roleId:", e.target.error);
                    };
                };

                usernameRequest.onerror = (e) => {
                    reject("Error retrieving username:", e.target.error);
                };
            };

            tokenRequest.onerror = (e) => {
                reject("Error retrieving authToken:", e.target.error);
            };
        };

        dbRequest.onerror = (event) => {
            reject(event.target.error);
        };
    });
};
