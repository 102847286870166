import React, { useEffect, useState } from "react";
import { fetchData } from "./api";
import { DropdownComponent } from "./DropdownComponent";
import { toast, ToastContainer } from "react-toastify";
import { retrieveUserDataFromIndexedDB } from './dbUtils';
import 'react-toastify/dist/ReactToastify.css';
import "./styles/tabs.css";
import { openOfflineFormsDB, addForm } from './indexedDBHelper';

const BASE_URL = "https://www.smcrioims.co.za";

function MooringRollOver() {
    const [data, setData] = useState({});
    const [selected, setSelected] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [formName, setFormName] = useState('');
    const [isActive, setIsActive] = useState(false);

    const generateOfflineForm = async () => {
        try {
            const db = await openOfflineFormsDB();
            const resolvedFormData = {
                name: formName,

                Organisation: {
                    id: selected.Organisation,
                    name: data.Organisation?.find(org => org.organisation_id === Number(selected.Organisation))?.name || selected.Organisation
                },
                Programme: {
                    id: selected.Programme,
                    name: data.Programme?.find(prog => prog.programme_id === Number(selected.Programme))?.name || selected.Programme
                },
                Project: {
                    id: selected.Project,
                    name: data.Project?.find(proj => proj.project_id === Number(selected.Project))?.name || selected.Project
                },
                Site: {
                    id: selected.Site,
                    name: data.Site?.find(site => site.site_id === Number(selected.Site))?.name || selected.Site
                },
                MooringType: {
                    id: selected.MooringType,
                    name: data.MooringType?.find(type => type.mooring_type_id === Number(selected.MooringType))?.name || selected.MooringType
                },
                Station: {
                    id: selected.Station,
                    name: data.Station?.find(station => station.station_id === Number(selected.Station))?.name || selected.Station
                },
                Mooring: {
                    id: selected.Mooring,
                    name: data.Mooring?.find(mooring => mooring.mooring_id === Number(selected.Mooring))?.name || selected.Mooring
                },
                Deployment: {
                    id: selected.Deployment,
                    name: data.Deployment?.find(deployment => deployment.deployment_id === Number(selected.Deployment))?.deploy_date || selected.Deployment
                },
                Logger: {
                    id: selected.Logger,
                    name: data.Logger?.find(logger => logger.logger_id === Number(selected.Logger))?.logger_type || selected.Logger
                }
            };


            const formId = await addForm(db, resolvedFormData);
            console.log(`Form added with ID: ${formId}`);
            setModalOpen(false); // Close the modal
            setIsActive(false); // Reset the isActive state
            toast.success("Form successfully submitted!");
        } catch (err) {
            console.error("Error generating offline form:", err);
            toast.error("Error submitting the form.");
        }
    };

    function FormNameModal() {
        return (
            <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '20px', zIndex: 1000 }}>
                <h3>Enter Form Name</h3>
                <input value={formName} onChange={e => setFormName(e.target.value)} placeholder="Form Name" />
                <button onClick={generateOfflineForm}>Submit</button>
            </div>
        );
    }



    useEffect(() => {
        fetchData(`${BASE_URL}/organisations`).then(fetchedData => setData(prev => ({ ...prev, Organisation: fetchedData })));
        fetchData(`${BASE_URL}/mooring_types`).then(fetchedData => setData(prev => ({ ...prev, MooringType: fetchedData })));
    }, []);

    const handleSelectChange = (level, value) => {
        const dropdownNames = ["Organisation", "Programme", "Project", "Site", "MooringType", "Station", "Mooring", "Deployment", "Logger"];  // Add "MooringType" here
        const currentDropdownIndex = dropdownNames.indexOf(level);

        if (currentDropdownIndex >= 0) {
            const dropdownsToReset = dropdownNames.slice(currentDropdownIndex + 1);
            const resetSelections = {};
            dropdownsToReset.forEach(dropdown => {
                resetSelections[dropdown] = null;
            });

            setSelected({ ...selected, [level]: value, ...resetSelections });
        }
    };


    useEffect(() => {
        const fetchDataForSelected = (key, url) => {
            if (url) {
                fetchData(`${BASE_URL}${url}`).then(fetchedData => setData(prev => ({ ...prev, [key]: fetchedData })));
            }
        };

        if (selected.Organisation) fetchDataForSelected('Programme', `/programmes/${selected.Organisation}`);


        if (selected.Programme) fetchDataForSelected('Project', `/projects/${selected.Programme}`);
        if (selected.Project) fetchDataForSelected('Site', `/sites/${selected.Project}`);
        if (selected.Site) fetchDataForSelected('Station', `/stations/${selected.Site}`);
        if (selected.MooringType && selected.Station) fetchDataForSelected('Mooring', `/moorings?stationId=${selected.Station}&typeId=${selected.MooringType}`);
        if (selected.Mooring) fetchDataForSelected('Deployment', `/deployments/${selected.Mooring}`);
        if (selected.Deployment) fetchDataForSelected('Logger', `/loggers/${selected.Deployment}`);
    }, [selected]);


    const dropdowns = [
        { label: "Select an Organisation", name: "Organisation", idField: "organisation_id", nameField: "name" },
        { label: "Select a Programme", name: "Programme", idField: "programme_id", nameField: "name" },
        { label: "Select a Project", name: "Project", idField: "project_id", nameField: "name" },
        { label: "Select a Site", name: "Site", idField: "site_id", nameField: "name" },
        { label: "Select a Mooring Type", name: "MooringType", idField: "mooring_type_id", nameField: "name" },
        { label: "Select a Station", name: "Station", idField: "station_id", nameField: "name" },        // ... other dropdowns
        { label: "Select a Mooring", name: "Mooring", idField: "mooring_id", nameField: "name" },
        { label: "Select a Deployment", name: "Deployment", idField: "deployment_id", nameField: "deploy_date" },
        { label: "Select a Logger", name: "Logger", idField: "logger_id", nameField: "logger_type" }
    ];

    const handleCreateNew = async () => {
        try {
            const userData = await retrieveUserDataFromIndexedDB();

            if (userData.token) {
                let roleMessage = userData.roleId === 1 ? "manager" : "technician";
                toast.success(`Logged in as ${roleMessage}!`);
            } else {
                // Redirect to login or show a message
                alert("Please log in to perform this action.");
            }
        } catch (err) {
            console.error("Error checking login status:", err);
        }
    };


    let buttonText = 'Disabled';


    if (selected.Station) {
        buttonText = 'Generate Predeployment Form';
    }
    if (selected.Mooring) {
        buttonText = 'Generate Rollover Form';
    }

    return (
        <div>




            <ToastContainer />

            <form>
                {dropdowns.map(({ label, name, idField, nameField }, index) => {
                    // Only render this dropdown if either it's the first one, or its parent has a selection
                    if (index === 0 || selected[dropdowns[index - 1].name]) {
                        return (
                            <DropdownComponent
                                key={index}
                                label={label}
                                options={data[name] || []}
                                value={selected[name]}
                                onChange={e => handleSelectChange(name, e.target.value)}
                                onCreateNew={() => handleCreateNew()}
                                idField={idField}
                                nameField={nameField}
                            />
                        );
                    }
                    return null;
                })}
            </form>
            <button
                className={`data-entry-button ${selected.Station || selected.Mooring ? 'active-button' : ''}`}
                onClick={() => setModalOpen(true)}
                disabled={!selected.Station} // The button will be disabled if 'Station' dropdown isn't selected.
            >
                {buttonText}
            </button>

            <div style={{fontSize: '12px', marginTop: '5px', color: '#808080'}}>
                <p>1. Select up to a station using the dropdowns to generate a predeployment form,</p>
                <p>2. or up to an existing mooring to generate a rollover form.</p>
            </div>

            {isModalOpen && <FormNameModal />}


        </div>
    );
}

export default MooringRollOver;
