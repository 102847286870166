export const DropdownComponent = ({ label, id, options, value, onChange, onCreateNew, idField = 'id', nameField = 'name' }) => (
    <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <select id={id} onChange={onChange} value={value || ""}>
            <option value="" disabled>Select</option>
            {options.map((opt) => (
                <option key={opt[idField]} value={opt[idField]}>
                    {opt[nameField]}
                </option>
            ))}
        </select>
        <button type="button" onClick={onCreateNew}>Create New</button>

    </div>
);
