import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import './styles/LoginForm.css';


// Utility function to store JWT
// Updated utility function to store JWT, username, and role ID
const storeData = (token, username, roleId) => {
    return new Promise((resolve, reject) => {
        const dbRequest = indexedDB.open("MyDatabase", 3);

        dbRequest.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('userData')) {
                db.createObjectStore('userData');
            }
        };

        dbRequest.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction(['userData'], 'readwrite');
            const store = transaction.objectStore('userData');

            store.put(token, 'authToken');
            store.put(username, 'username');
            store.put(roleId, 'role_id'); // Storing role ID

            transaction.oncomplete = () => resolve();
            transaction.onerror = () => reject(transaction.error);
        };

        dbRequest.onerror = (event) => {
            reject(event.target.error);
        };
    });
};

function LoginForm() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://www.smcrioims.co.za/login', {
                username,
                password,
            });

            if (response.data.token) {
                const user = response.data.user;
                const userName = user && user.username ? user.username : username;
                const roleId = user && user.role_id;

                if (!roleId) {
                    throw new Error("User role not provided");
                }

                try {
                    await storeData(response.data.token, userName, roleId);  // Storing role ID here
                    console.log("Token, username, and role ID stored in indexedDB");
                    navigate('/profile');
                } catch (err) {
                    console.error("Failed to store in indexedDB:", err);
                    setError('Failed to store user data. Please try again.');
                }
            }

        } catch (err) {
            console.error(err);
            setError('Failed to log in. Please try again.');
        }
    };


    return (
        <div className="login-container">
            <div className="login-form">
                <h2>Login</h2>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="login-form input"
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="login-form input"
                />

                <button onClick={handleLogin} className="login-form button">Login</button>

                {error && <div className="error-message">{error}</div>}

                <div className="login-form div">
                    Don't have an account? <Link to="/register">Register</Link>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;
