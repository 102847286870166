import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Link, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import logo from './SMCRI-Logo-1.png';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles/tabs.css';
import RegistrationForm from './RegistrationForm';
import LoginForm from './LoginForm';
import Home from './HomeTab';
import {deleteUserDataFromDB, openDB, retrieveUserData, sendPendingRegistrations} from './dbUtils';
import DataEntry from './DataEntry';
import Tests from './Tests';
import OfflineForms from './OfflineForms';
import { retrieveUserDataFromIndexedDB } from './dbUtils';

function Logout({setToken, setUsername}) {
    const navigate = useNavigate();

    useEffect(() => {
        const confirmLogout = window.confirm("Are you sure you want to sign out? Please note: You'll need an active internet connection to log back in.");

        if (!confirmLogout) {
            navigate(-1); // Go back to the previous page if the user clicks "Cancel"
            return;
        }

        // window.alert("Please note: You'll need an active internet connection to log back in.");

        (async () => {
            try {
                await deleteUserDataFromDB();
                setToken(null);
                setUsername(null);
                navigate('/login');
            } catch (err) {
                console.error("Error deleting from indexedDB:", err);
            }
        })();
    }, []);

    return <div>Logging out...</div>;
}


function Profile({ setToken: setParentToken, setUsername: setParentUsername }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [username, setUsername] = useState(null);
    const [roleId, setRoleId] = useState(null);
    const [users, setUsers] = useState([]);
    const [editingRoles, setEditingRoles] = useState(false);
    const [organisations, setOrganisations] = useState([]);
    const [editingOrganisations, setEditingOrganisations] = useState(false);


    useEffect(() => {
        retrieveUserDataFromIndexedDB()
            .then(data => {
                const validatedToken = typeof data.token === 'string' ? data.token : null;
                const validatedUsername = typeof data.username === 'string' ? data.username : null;

                setToken(validatedToken);
                setUsername(validatedUsername);
                setRoleId(data.roleId);

                setParentToken(validatedToken);
                setParentUsername(validatedUsername);
            })
            .catch(err => {
                console.error("Error fetching from indexedDB:", err);
            });

        if (roleId === 1) {
            fetch('/users')
                .then(response => {
                    // console.log(response);
                    return response.json();
                })
                .then(data => {
                    setUsers(data);
                })
                .catch(error => {
                    console.error("Error fetching users:", error);
                });

            // Fetching organisations
            fetch('/organisations')
                .then(response => response.json())
                .then(data => {
                    setOrganisations(data);
                })
                .catch(error => {
                    console.error("Error fetching organisations:", error);
                });




        }

    }, [location.pathname, roleId]);

    const handleEditRoles = () => {
        setEditingRoles(!editingRoles);  // Toggle the editing state
    };

    const handleRoleChange = (userId, newRoleId) => {
        const userToEdit = users.find(user => user.id === userId);

        // Check if the user being edited is the current user
        if (userToEdit.username === username) {
            toast.warning("You cannot change the role of the current user.");
            return;
        }

        const updatedUsers = users.map(user => {
            if (user.id === userId) {
                user.role_id = newRoleId;
            }
            return user;
        });
        setUsers(updatedUsers);

        // TODO: You can also update the user role in the database here, using fetch or any other method you've implemented.
    };


    if (!token || !username) {
        return (
            <div>
                <p>You are not logged in.</p>
                <button onClick={() => navigate("/login")}>Login</button>
            </div>
        );
    }

    const handleSubmitUser = async (userId) => {
        const userToUpdate = users.find(user => user.id === userId);

        if (!userToUpdate) {
            console.error("Couldn't find user with ID:", userId);
            toast.error("Error finding the user to update.");
            return;
        }

        // Prompt user for confirmation
        const isConfirmed = window.confirm("Are you sure you want to update this user?");
        if (!isConfirmed) {
            toast.info("Update cancelled.");
            return;
        }

        const userData = {
            name: userToUpdate.name,
            surname: userToUpdate.surname,
            email_address: userToUpdate.email_address,
            organisation: userToUpdate.organisation,
            username: userToUpdate.username,
            role_id: userToUpdate.role_id
        };

        try {
            const response = await fetch(`/users/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            const data = await response.text();

            if (response.ok) {
                toast.success("User updated successfully!");
                console.log(data);
            } else {
                toast.error("Error updating user.");
                console.error("Error updating user:", data);
            }

        } catch (error) {
            toast.error("Error making PUT request.");
            console.error("Error making PUT request:", error);
        }
    };

    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop />

            <h2>Welcome, {username}</h2>
            {roleId === 1 &&
            <button style={{ margin: "10px 0", padding: "8px 15px" }} onClick={handleEditRoles}>
                Edit User Roles
            </button>
            }

            {editingRoles && (
                <div style={{ overflowX: "auto" }}> {/* Makes the table scrollable on smaller screens */}
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                        <tr>
                            <th style={{ padding: "10px", borderBottom: "2px solid #333" }}>Username</th>
                            <th style={{ padding: "10px", borderBottom: "2px solid #333" }}>Email</th>
                            <th style={{ padding: "10px", borderBottom: "2px solid #333" }}>Role</th>
                            <th style={{ padding: "10px", borderBottom: "2px solid #333" }}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map(user => (
                            <tr key={user.id}>
                                <td style={{ padding: "10px", borderBottom: "1px solid #eee" }}>{user.username}</td>
                                <td style={{ padding: "10px", borderBottom: "1px solid #eee" }}>{user.email_address}</td>
                                <td style={{ padding: "10px", borderBottom: "1px solid #eee" }}>
                                    <select value={user.role_id} onChange={(e) => handleRoleChange(user.id, parseInt(e.target.value))}>
                                        <option value="1">Manager</option>
                                        <option value="2">Employee</option>
                                    </select>
                                </td>
                                <td style={{ padding: "10px", borderBottom: "1px solid #eee" }}>
                                    {user.username !== username ? (
                                        <button style={{ marginRight: "10px", padding: "5px 10px" }} onClick={() => handleSubmitUser(user.id)}>Submit</button>
                                    ) : (
                                        <span>Current User</span>
                                    )} </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}


function MainContent({token: initialToken, username: initialUsername, stagedEntriesCount, setStagedEntriesCount}) {
    const navigate = useNavigate();

    const validatedToken = typeof initialToken === 'string' ? initialToken : null;
    const validatedUsername = typeof initialUsername === 'string' ? initialUsername : null;
    const [token, setToken] = useState(validatedToken);
    const [username, setUsername] = useState(validatedUsername);

    return (
        <div>
            <div className={styles.userInfoBar}>
                {token && <p className={styles.loggedInText}>Logged in as {username}</p>}
                <nav>
                    <ul className={styles.navList}>
                        <li><Link to="/" className={styles.navLink}>Operations dahsboard</Link></li>
                        <li>
                            {/*console.log(stagedEntriesCount);*/}
                            <Link to="/data-entry" className={styles.navLink}>
                                 Online Data Entry
                                {stagedEntriesCount > 0 && (
                                    <span style={{
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                        padding: '5px 10px',
                                        color: 'white',
                                        display: 'inline-block',
                                        fontSize: '0.8em',
                                        marginLeft: '5px'
                                    }}>
    {stagedEntriesCount}
</span>
                                )}
                            </Link>


                        </li>
                        <li><Link to="/offline" className={styles.navLink}>Offline Forms</Link></li>
                        <li><Link to="/test" className={styles.navLink}>Tests</Link></li>
                        <li><Link to="/profile" className={styles.navLink}>Profile</Link></li>
                        {token ? (
                            <li><Link to="/logout" className={styles.navLink}>Logout</Link></li>
                        ) : (
                            <>
                                <li><Link to="/login" className={styles.navLink}>Login</Link></li>
                                <li><Link to="/register" className={styles.navLink}>Register</Link></li>
                            </>
                        )}
                    </ul>
                </nav>
            </div>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/data-entry" element={<DataEntry/>}/>
                <Route path="/offline" element={<OfflineForms/>}/>
                <Route path="/test" element={<Tests/>}/>
                <Route path="/login" element={<LoginForm/>}/>
                <Route path="/logout" element={<Logout setToken={setToken} setUsername={setUsername}/>}/>
                <Route path="/register" element={<RegistrationForm/>}/>
                <Route path="/profile" element={<Profile token={token} username={username} setToken={setToken}
                                                         setUsername={setUsername}/>}/>
            </Routes>
        </div>
    );
}

function App() {
    const [token, setToken] = useState(null);
    const [username, setUsername] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [stagedEntriesCount, setStagedEntriesCount] = useState(parseInt(localStorage.getItem("stagedEntriesCount")) || 0);

    useEffect(() => {
        function handleStorageChange(e) {
            if (e.key === "stagedEntriesCount") {
                setStagedEntriesCount(parseInt(e.newValue, 10));
            }
        }

        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);
    useEffect(() => {
        // This will set up or upgrade the database if needed and then open it
        openDB().then(() => {
            console.log("Database opened or set up successfully.");
        }).catch(err => {
            console.error("Error opening or setting up database:", err);
        });
        sendPendingRegistrations();


        retrieveUserData()
            .then(data => {
                console.log(data);
                // Protective Rendering: Ensure token and username are of the correct types
                const validatedToken = typeof data.token === 'string' ? data.token : null;
                const validatedUsername = typeof data.username === 'string' ? data.username : null;

                setToken(validatedToken);
                setUsername(validatedUsername);
                setIsLoading(false);
            })
            .catch(err => {
                console.error("Error fetching from indexedDB:", err);
                setError("Failed to fetch user data from IndexedDB."); // Update error state
                setIsLoading(false);
            });

        // Set up event listener for online event
        const handleOnlineEvent = () => {
            sendPendingRegistrations();
        };
        window.addEventListener('online', sendPendingRegistrations);

        return () => {
            window.removeEventListener('online', handleOnlineEvent);
        };


    }, []);


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            {error && <p className="error">{error}</p>}

            <div className={styles.logoContainer}>
                <img src={logo} alt="App Logo" className={styles.logo}/>
            </div>


            <MainContent token={token} username={username} stagedEntriesCount={stagedEntriesCount}/>

        </Router>
    );
}

export default App;
