import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, CircleMarker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./styles/tabs.css";

const Home = () => {
    const [deployments, setDeployments] = useState([]);
    const position = [-33.9608, 25.6022]; // Port Elizabeth coordinates

    useEffect(() => {
        fetch("/deploymentStatus")
            .then((res) => res.json())
            .then((data) => setDeployments(data))
            .catch((err) => console.error("Failed to fetch deployment statuses:", err));
    }, []);

    const getColorForStatus = (color_status) => {
        switch (color_status) {
            case "green":
                return "#00FF00";
            case "orange":
                return "#FFA500";
            case "red":
                return "#FF0000";
            case "black":
                return "#000000";
            case "grey":
                return "#808080";
            case "yellow":
                return "#FFFF00";
            default:
                return "#FFFFFF";
        }
    };

    const [activeFilters, setActiveFilters] = useState({
        green: true,
        orange: true,
        red: true,
        black: true,
        yellow: true,
    });

    const toggleFilter = (color) => {
        setActiveFilters((prev) => ({ ...prev, [color]: !prev[color] }));
    };

    const Legend = () => (
        <div className="legendContainer">
            <h3>Deployment Status</h3>
            <div onClick={() => toggleFilter("green")}>
                <span className="legendColor" style={{ backgroundColor: "#00FF00", opacity: activeFilters.green ? 1 : 0.3 }}></span>
                Deployed within the last week
            </div>
            <div onClick={() => toggleFilter("orange")}>
                <span className="legendColor" style={{ backgroundColor: "#FFA500", opacity: activeFilters.orange ? 1 : 0.3 }}></span>
                Deployed within the last month
            </div>
            <div onClick={() => toggleFilter("red")}>
                <span className="legendColor" style={{ backgroundColor: "#FF0000", opacity: activeFilters.red ? 1 : 0.3 }}></span>
                Deployed over a month ago
            </div>
            <div onClick={() => toggleFilter("black")}>
                <span className="legendColor" style={{ backgroundColor: "#000000", opacity: activeFilters.black ? 1 : 0.3 }}></span>
                Overdue for retrieval
            </div>
            <div onClick={() => toggleFilter("yellow")}>
                <span className="legendColor" style={{ backgroundColor: "#FFFF00", opacity: activeFilters.yellow ? 1 : 0.3 }}></span>
                Taken out and awaiting redeployment
            </div>
        </div>
    );

    return (
        <div className="dashboardContainer">
            <Legend />
            <div className="mapContainer">
                <MapContainer center={position} zoom={8} style={{ height: "100%", width: "100%" }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {deployments
                        .filter((deployment) => activeFilters[deployment.color_status])
                        .map((deployment) => (
                            //... your existing map marker rendering code
                            <CircleMarker key={deployment.deployment_id} center={[deployment.Latitude, deployment.Longitude]} color={getColorForStatus(deployment.color_status)} fillColor={getColorForStatus(deployment.color_status)} fillOpacity={1} radius={5}>
                                <Popup>
                                    <div>
                                        <strong>Deployment ID:</strong> {deployment.deployment_id}
                                        <br />
                                        <strong>Mooring ID:</strong> {deployment.mooring_id}
                                        <br />
                                        <strong>Longitude:</strong> {deployment.Longitude}
                                        <br />
                                        <strong>Latitude:</strong> {deployment.Latitude}
                                        <br />
                                        <strong>Retrieve Date:</strong> {deployment.retrieve_date}
                                        <br />
                                        <strong>Retrieve Time:</strong> {deployment.retrieve_time}
                                        <br />
                                        <strong>Deploy Date:</strong> {deployment.deploy_date}
                                        <br />
                                        <strong>Deploy Time:</strong> {deployment.deploy_time}
                                        <br />
                                        <strong>Deployment Duration (Days):</strong> {deployment.deployment_duration_days}
                                        <br />
                                        <strong>Total Duration (Days):</strong> {deployment.total_duration_days}
                                        <br />
                                        <strong>Data File:</strong> {deployment.datafile}
                                        <br />
                                        <strong>Comments:</strong> {deployment.comments}
                                        <br />
                                        <strong>Acoustic Release Number:</strong> {deployment.acoustic_release_number}
                                        <br />
                                        <strong>Deployment Number:</strong> {deployment.deplyoment_number}
                                        <br />
                                        <strong>Status:</strong> {deployment.raw_status}
                                        <br />
                                    </div>
                                </Popup>
                            </CircleMarker>
                        ))}
                </MapContainer>
            </div>
        </div>
    );
};

export default Home;
