// serviceWorkerRegistration.js
const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;




export function register() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            registerServiceWorker(swUrl);
        });
    } else {
        console.log('Service Worker is not supported in this browser.');
    }
}

function registerServiceWorker(url) {
    navigator.serviceWorker.register(url).then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);

        registration.onupdatefound = () => {
            handleUpdateFound(registration);
        };

    }).catch(error => {
        console.error('Error during service worker registration:', error);
    });
}

function handleUpdateFound(registration) {
    const installingWorker = registration.installing;

    installingWorker.onstatechange = () => {
        switch (installingWorker.state) {
            case 'installed':
                if (navigator.serviceWorker.controller) {
                    console.log('New content is available; please refresh.');
                } else {
                    console.log('Content is cached for offline use.');
                }
                break;
            case 'redundant':
                console.error('The installing service worker became redundant.');
                break;
        }
    };
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
                console.log('Service Worker unregistered.');
            }).catch(error => {
                console.error('Error during service worker unregistration:', error);
            });
        });
    }
}
