import React, { useState } from 'react';
import Questionnaire from './MooringRollOver';
import './styles/DataEntry.css'; // Import the stylesheet

function DataEntry() {
    const [activeForm, setActiveForm] = useState(null);

    const handleClick = (formName) => {
        setActiveForm(formName);
    };

    return (
        <div className="data-entry-container">
            <h2 className="data-entry-header">Online Data Entry</h2>

            {/*<button*/}
            {/*    className={`data-entry-button ${activeForm === 'mooringPredeployment' && 'active-button'}`}*/}
            {/*    onClick={() => handleClick('mooringPredeployment')}*/}
            {/*>*/}
            {/*    Mooring Predeployment*/}
            {/*</button>*/}
            <button
                className={`data-entry-button ${activeForm === 'mooringRollover' && 'active-button'}`}
                onClick={() => handleClick('mooringRollover')}
            >
                Moorings
            </button>

            <div className="form-container">

                {/*{activeForm === 'mooringPredeployment' && <MooringPredeployment />}*/}
                {activeForm === 'mooringRollover' && <Questionnaire />}
            </div>
        </div>
    );
}

export default DataEntry;
